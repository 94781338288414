import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/production/kiki/private/produceWorkOrderTask/'
const baseUrl1 = 'api/web/v1/production/kiki/private/'

export default {
  // 分页-生产-工序任务
  getTaskpage(data) {
    return request.get(baseUrl + 'getProduceWorkOrderTaskListOfOwnPage', data)
  },
  // 不分页- 生产-工序任务
  getTaskpageno(data) {
    return request.get(baseUrl + 'getProduceWorkOrderTaskListOfOwn', data)
  },
  // 分页-工单-工序任务
  getOrderTaskpage(data) {
    return request.get(baseUrl + 'getProduceWorkOrderTaskListPage', data)
  },
  // 不分页-工单-工序任务
  getOrderTaskpageno(data) {
    return request.get(baseUrl + 'getProduceWorkOrderTaskList', data)
  },
  // 下发至工位
  getTaskStation(data) {
    return request.post(baseUrl1 + 'produceWorkOrderTaskStationMapping/addProduceWorkOrderTaskStationMappingBatch', data)
  },
  // 指派用户
  getTaskUse(data) {
    return request.post('api/web/v1/production/private/productionTask/assignProductionTask', data)
  },
  // 指派用户
  getTaskUseLine(data) {
    return request.post(baseUrl1 + 'produceWorkOrderTaskUserMapping/addProduceWorkOrderTaskUserMappingBatch', data)
  },
  // 获取用户、用户组
  getUsers(data) {
    return request.get('api/web/v1/production/private/productionTask/getUserAndUserGroupList', data)
  },
  // 获取用户、用户组
  getUsersLine(data) {
    return request.get(baseUrl + 'getUserAndGroupListOfAssign', data)
  },
  // 任务id和工位id查找用户
  getTaskUserId(data) {
    return request.get(baseUrl1 + 'produceWorkOrderTaskUserMapping/getProduceWorkOrderTaskUserMappingList', data)
  },
  // 取消下发、指派
  cancelAssign(productionTaskId) {
    return request.put('api/web/v1/production/private/productionTask/cancelAssignProductionTask?productionTaskId=' + productionTaskId)
  },
  // 取消下发、指派
  cancelAssignLine(data) {
    return request.del(baseUrl + 'cancelAssign', data)
  },
  // 分页-外协出入库记录
  getOutsourcingTaskList(data) {
    return request.get(baseUrl1 + 'outsourcingRecord/getOutsourcingRecordListPage', data)
  },
  // 不分页-外协出入库记录
  getOutsourcingTaskPage(data) {
    return request.get(baseUrl1 + 'outsourcingRecord/getOutsourcingRecordList', data)
  },
  // 减产原因
  getReductionReasonList(data) {
    return request.get(baseUrl1 + 'produceReductionRecord/getReasonList', data)
  },
  // 拆单原因
  getDivisionReasonList(data) {
    return request.get(baseUrl1 + 'produceDivisionRecord/getReasonList', data)
  },
  // 减产
  saveReduction(data) {
    return request.post(baseUrl1 + 'produceWorkOrder/reduce', data)
  },
  // 拆单
  saveDivision(data) {
    return request.put(baseUrl1 + 'produceWorkOrder/division', data)
  },
  // 创建计划
  addRepairRecord(data) {
    return request.post(baseUrl1 + `producePlan/confirmDivision`, data)
  },
  // 取得批次码
  getBatchNumber(id) {
    return request.get(baseUrl1 + `producePlan/getDivisionPlanBatchNumber?divisionRecordId=${id}`)
  },
  // 取得批次码获取的工单
  getBatchNumberWorkOrder(data) {
    return request.get(baseUrl1 + `produceWorkOrderBatchNumberMapping/getProduceWorkOrderBatchNumberMappingListPage`, data)
  },
  // 分批次撤回出站
  cancelCheckOutPartial(data) {
    return request.put(baseUrl + 'cancelCheckOutPartial', data)
  },
  // 分批次撤回进站
  cancelCheckInPartial(data) {
    return request.put(baseUrl + 'cancelCheckInPartial', data)
  },
  // 重启任务
  restartTask(productionTaskId) {
    return request.put('api/web/v1/production/private/productionTask/restart?productionTaskId=' + productionTaskId)
  },
  // 工时统计列表
  getWorkingHoursList(data) {
    return request.post(`api/web/v1/production/private/workHoursStatistics/list`, data)
  },
  // 工时统计列表导出
  exportWorkingHoursList(data) {
    return request.exportPost('api/web/v1/production/private/workHoursStatistics/export', data)
  },
  // 生产任务批量指派
  batchAssignProductionTask(data) {
    return request.post(`api/web/v1/production/private/productionTask/batchAssignProductionTask`, data)
  },
  // 工时统计编辑
  updateWorkingHours(data) {
    return request.post(`api/web/v1/production/private/workHoursStatistics/update`, data)
  },
  // 工时统计删除
  delWorkingHours(data) {
    return request.post(`api/web/v1/production/private/workHoursStatistics/delete`, data)
  },
  // 生产任务全部指派
  allAssignProductionTask(data) {
    return request.post(`api/web/v1/production/private/productionTask/allAssignProductionTask`, data)
  },
  // 工时原因列表
  getWorkHoursReasonList(data) {
    return request.get('api/web/v1/basicData/private/workHoursReason/getWorkHoursReasonList', data)
  },
  // 延期反馈
  addDelayFeedback(data){
    return request.post('api/web/v1/task/private/procedureTask/addDelayFeedback', data)
  },
}
