<template>
  <div class="side-bar-container" @mouseleave="siderIconLeave">
    <div class="container-left" :class="!opened ? 'closed' : ''">
      <div class="left-top" v-if="!opened">
        <img src="../../../assets/l_c_H_images/logo_mini.png"/>
      </div>
      <div class="left-top" v-else>
        <template v-if="memberCode === 'yyhl'">
          <img v-if="logoImg" :src="logoImg"/>
          <img v-else src="../../../assets/l_c_H_images/8888.png"/>
        </template>
        <template v-else>
          <img v-if="logoImg" :src="logoImg"/>
          <img v-else src="../../../assets/l_c_H_images/logo.png"/>
        </template>
      </div>
      <div class="left-center">
        <ul>
          <template v-for="item in menuList">
            <li
              v-if="!item.hidden && hasAuth(item) && item.meta.activeMenu != 'applyMarket' && item.meta.activeMenu != 'applyDetails'"
              :key="item.name"
              :class="liClass(item)"
              @click="openSecond(item)"
              @mouseenter="siderIconHover(item)"
            >
              <div class="img-wrapper"><img :src="item.meta.icon"/></div>
              <span v-if="opened">{{ item.meta.title }}</span>
            </li>
          </template>
        </ul>
        <div class="left-bottom">
          <div class="switch-side-bar" v-if="opened" @click="onFullScreen()">
            <img src="../../../assets/l_c_H_images/全屏收起.png" alt="">
          </div>
          <div class="switch-side-bar arrow" @click="switchOpened()">
            <img src="../../../assets/l_c_H_images/收起.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <SecondBar :list="secondMenu" :parent-title="parentTitle" :roles="roles" :siderIconHovered="siderIconHovered"/>

    <!--日志 -->
    <MDialog
      ref="models"
      v-model="showVisible"
      :show-footer="false"
      :fullscreen="fullscreen"
      :append-to-body="true"
      @onOk="showVisible = false"
      class="show-model"
      style="height: 100%"
    >
      <div slot="title" class="head-alls">
        <div class="title">更新日志</div>
        <div class="head-ri">
          <el-button v-if="(name + 'Add', false)" type="primary" class="li-btn" size="small" @click="addModel">新增
          </el-button>
          <div @click="showFull">
            <img v-if="fullscreen" class="ri-img" src="@/assets/narrow-amplification/narrow.png">
            <img v-else class="ri-img" src="@/assets/narrow-amplification/amplification.png" alt="">
          </div>
        </div>
      </div>
      <div class="all-box">
        <div v-for="item in list" :key="item.id" class="all-li"
             :class="permission(name + 'Modify', false) ? 'all-lis' : ''">
          <div class="li-le"/>
          <div class="li-ri">
            <div class="ri-top">
              <div class="title">
                {{ item.updateTime }}
              </div>
              <div v-if="permission(name + 'Modify', false)" class="img" @click="edits(item)"/>
              <div v-if="permission(name + 'Del', false)" class="imgs" @click="delData(item.id)"/>
            </div>
            <div class="des" v-html="countOut(item.content)"/>
          </div>
        </div>
      </div>
    </MDialog>
    <MDialog v-model="addVisible" :title="title" :append-to-body="true" @onOk="add">
      <el-form ref="ruleForm" label-position="right" :model="ruleForm" :rules="rules" label-width="100px"
               class="demo-form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="更新日期：" prop="updateTime">
              <el-date-picker
                type="date"
                placeholder="请选择"
                v-model="ruleForm.updateTime"
                style="width: 80%"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="更新内容：" prop="content">
              <el-input v-model="ruleForm.content" type="textarea" :autosize="{ minRows: 10 }" style="width: 80%"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </MDialog>
  </div>
</template>

<script>
/** write by luomingzhi */
import { constantRoutes } from '@/router'
import SecondBar from './SecondBar.vue'
import { getShowMenu, getTreePath } from '@/utils'
import api from '@/api/log'
import VueQr from 'vue-qr'
import baseSetting from '@/api/sets/base-setting'

export default {
  name: 'Index',
  components: { VueQr, SecondBar },
  data() {
    return {
      activeMenuName: '',
      parentTitle: '',
      menuList: constantRoutes,
      secondMenu: [],
      showVisible: false,
      addVisible: false,
      ruleForm: {
        updateTime: '',
        content: ''
      },
      rules: {
        updateTime: [{ required: true, message: '请选择更新日期', trigger: 'change' }],
        content: [{ required: true, message: '请输入更新内容', trigger: 'blur' }]
      },
      list: [],
      selectId: '',
      title: '新增更新日志',
      name: 'log',
      fullscreen: false,
      version: '',
      versionNumber: '',
      logoImg: '',
      siderIconHovered: false
    }
  },
  computed: {
    opened() {
      return this.$store.state.app.sidebar.opened
    },
    roles() {
      return this.$store.state.user.roles
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  watch: {
    // opened(val) {
    //   if (val) {
    //     this.setCurrentNameAndSM()
    //   }
    // }
  },
  mounted() {
    this.setCurrentNameAndSM()
    if (sessionStorage.getItem('memberCode') === 'mubai') {
      this.getList()
    }
    this.getCode()
  },
  methods: {
    // 获取logo
    async getCode() {
      const res = await baseSetting.getCode({ code: 'companyLogo' })
      if (res) {
        this.logoImg = res.isShow === 1 ? res.value : ''
      }
    },
    switchOpened() {
      this.siderIconHovered = false
      // 1. 判断当前选中主菜单是否有子节点
      const currentName = this.$store.state.app.sidebar.currentMainMenuName
      const paths = getTreePath(constantRoutes, currentName, 'path')
      if (paths.length) {
        const currentMenu = constantRoutes.find(item => item.path === paths[0])
        if (currentMenu) {
          this.$store.dispatch('app/toggleSideBar')
        }
      }
    },
    onFullScreen() {
      this.siderIconHovered = false
      this.$store.dispatch('app/openFullScreen')
    },
    addModel() {
      this.selectId = ''
      this.ruleForm = {
        updateTime: '',
        content: ''
      }
      this.addVisible = true
    },
    showFull() {
      this.fullscreen = !this.fullscreen
      this.$refs.models.showFull()
    },
    countOut(val) {
      return val.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')
    },
    add(done) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.getDatas()
        } else {
          return false
        }
      })
      done()
    },
    async getDatas() {
      if (this.selectId) {
        const res = await api.modifyLog(
          Object.assign({}, this.ruleForm, {
            id: this.selectId
          })
        )
        if (res) {
          this.$message.success('修改成功')
          this.addVisible = false
          this.getList()
        }
      } else {
        const ress = await api.addLog(this.ruleForm)
        if (ress) {
          this.$message.success('新增成功')
          this.addVisible = false
          this.getList()
        }
      }
    },
    edits(item) {
      this.title = '修改更新日志'
      this.ruleForm.updateTime = item.updateTime
      this.ruleForm.content = item.content
      this.selectId = item.id
      this.addVisible = true
    },
    async delData(id) {
      const res = await api.delLog(id)
      if (res) {
        this.getList()
        this.$message.success('删除成功')
      }
    },
    async getList() {
      const res = await api.getLogList()
      if (res) {
        this.list = res
      }
    },
    hasAuth(menu) {
      return this.roles.indexOf(menu.name) !== -1
    },
    liClass(menu) {
      return this.activeMenuName === menu.meta.activeMenu
        ? getShowMenu(menu.children).length && this.opened
          ? 'has-children active'
          : 'active'
        : 'inactive'
    },
    setCurrentNameAndSM() {
      // 1. 找到当前路由匹配的一级菜单Name
      // 2. 设置Name为Active菜单
      // 3. 如果检查有二级菜单则为secondMenu赋值
      // 4. 如果检查有二级菜单则为parentTitle赋值
      // 4. 如果没有二级菜单将二次菜单收缩状态改为false
      const currentName = this.$route.meta.activeMenu
      const paths = getTreePath(this.menuList, currentName, 'path')
      this.activeMenuName = paths[0]
      if (paths.length) {
        const firstFloorMenu = this.menuList.find(item => item.path === paths[0])
        const showChildren = getShowMenu(firstFloorMenu.children)
        const flag = firstFloorMenu && showChildren.length
        this.secondMenu = flag ? showChildren : []
        this.parentTitle = flag ? firstFloorMenu.meta.title : ''
        this.$store.dispatch('app/updateState', { name: 'sidebar', data: { currentMainMenuName: paths[0] } })
        if (!flag) this.$store.dispatch('app/closeSecondSideBar', 'close')
        if (this.$route.path === '/home/fun') {
          this.$router.push({ name: showChildren[0].children[0].name })
        }
      }
    },
    openSecond(menu) {
      this.siderIconHovered = false
      this.activeMenuName = menu.meta.activeMenu
      this.$store.dispatch('app/updateState', { name: 'sidebar', data: { currentMainMenuName: menu.path } })
      const showChildren = getShowMenu(menu.children)
      if (menu.children && showChildren.length) {
        this.$store.dispatch('app/openSecondSideBar')
        this.parentTitle = menu.meta.title
        this.secondMenu = showChildren
      } else {
        this.$store.dispatch('app/closeSecondSideBar', 'close')
        this.parentTitle = menu.meta.title
        this.secondMenu = showChildren
        this.$router.push({ name: menu.name })
      }
    },
    siderIconHover(menu) {
      if (!this.opened) {
        this.siderIconHovered = true
        this.activeMenuName = menu.meta.activeMenu
        this.parentTitle = menu.meta.title
        const showChildren = getShowMenu(menu.children)
        this.secondMenu = showChildren
      } else {
        this.siderIconHovered = false
      }
    },

    siderIconLeave() {
      this.siderIconHovered = false
    }
  }
}
</script>
<style lang="scss">
.popover-class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
<style scoped lang="scss">
* {
  font-size: 14px;
}

::v-deep .mubai-modal-wrap .el-dialog__body {
  padding: 0px 0 30px 0px !important;
}

::v-deep .mubai-modal-wrap .el-dialog__header {
  padding: 15px 20px !important;
}

::v-deep .el-dialog__headerbtn {
  top: 14.5px !important;
}

.side-bar-container {
  display: inline-flex;
  // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.29);
  height: 100%;
  position: relative;
  z-index: 1;
  background: linear-gradient(to bottom, #D0DAFF, #CFE2FB, #CDD9E6);

  .container-left {
    display: flex;
    flex-direction: column;

    &.closed {
      ul {
        width: 50px;
      }

      .left-top img {
        width: 24px;
      }

      .left-bottom {
        .arrow img {
          transform: rotate(180deg);
        }
      }
    }
  }

  .left-top {
    margin-top: 18px;
    padding-left: 10px;

    img {
      width: 120px;
    }
  }

  .left-bottom {
    width: 100%;
    height: 40px;
    padding: 10px 16px;
    display: flex;
    transition: all .5s;
    justify-content: space-evenly;

    img {
      cursor: pointer;
    }
  }

  .left-center {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
  }

  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ul {
    flex: 1;
    height: 0;
    position: relative;
    width: 150px;
    transition: all .5s;
    overflow-y: auto;
    display: inline-block;
  }

  li {
    height: 40px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    margin: 11px 0;
    cursor: pointer;
    color: #393d60;

    .img-wrapper {
      width: 30.66px;
      text-align: center;
      display: inline-flex;
      align-items: center;

      img {
        padding-right: 8px;
        height: 14px;
      }
    }

    span {
      position: relative;
      top: 1px;
      word-break: keep-all;
    }

    &:nth-child(3),
    &:nth-child(6) {
      img {
        position: relative;
        top: 1px;
      }
    }
  }

  li.active {
    background: linear-gradient(90deg, #5C76F1 0%, #7233EC 100%);
    position: relative;
    color: #ffffff;

    .img-wrapper {
      width: 30.66px;
      text-align: center;
      display: inline-flex;
      align-items: center;
      filter: brightness(100);

      img {
        padding-right: 8px;
        height: 14px;
      }
    }

    &:hover {
      background: linear-gradient(90deg, #5C76F1 0%, #7233EC 100%);
    }
  }

  li:hover {
    background: #E7EDF4;
  }

  li.has-children {
    &::after {
      content: '';
      position: absolute;
      top: 16px;
      right: 14px;
      transform: rotate(45deg);
      border-color: #ccc;
      border-top: 2px solid;
      border-right: 2px solid;
      width: 8px;
      height: 8px;
    }
  }
}

.demo-form {
  padding-top: 20px;
}

.head-alls {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    height: 20px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #393d60;
  }

  .head-ri {
    cursor: pointer;
    margin-right: 40px;
    display: flex;
    align-items: center;

    .li-btn {
      width: 88px;
      height: 32px;
      margin-right: 25px;
    }

    img {
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }
}

.show-model {
  width: 100%;
  z-index: 20000;

  .btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .all-box {
    width: 100%;

    .all-lis {
      &:hover {
        background: rgba(96, 127, 255, 0.1);
      }
    }

    .all-li {
      display: flex;
      justify-content: flex-start;
      padding: 20px 0 10px 20px;

      .li-le {
        width: 18px;
        height: 18px;
        background: url('../../../assets/split/rizhi.png') no-repeat;
        background-size: 100% 100%;
        margin-right: 10px;
      }

      .li-ri {
        .ri-top {
          display: flex;
          justify-content: flex-start;

          .title {
            height: 18px;
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #4a4f76;
            line-height: 18px;
            margin-bottom: 10px;
          }

          .img {
            width: 15px;
            height: 15px;
            margin: 0 20px;
            background: url('../../../assets/split/edit.png') no-repeat;
            background-size: 100%;
            cursor: pointer;

            &:hover {
              background: url('../../../assets/split/edit-active.png') no-repeat;
              background-size: 100%;
            }
          }

          .imgs {
            width: 14px;
            height: 15px;
            background: url('../../../assets/split/del.png') no-repeat;
            background-size: 100%;
            cursor: pointer;

            &:hover {
              background: url('../../../assets/split/del-active.png') no-repeat;
              background-size: 100%;
            }
          }
        }

        .des {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4a4f76;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
