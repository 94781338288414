import request from '@/utils/requestForAsync'

export default {
  // 获取问题等级-分页
  getProblemLevelListPage(data) {
    return request.get('api/web/v1/qualityMeasure/private/problemLevel/getProblemLevelListPage', data)
  },
    // 获取问题等级-不分页
    getProblemLevelList(data) {
      return request.get('api/web/v1/qualityMeasure/private/problemLevel/getProblemLevelList', data)
    },
  // 新增问题等级
  addProblemLevel(data) {
    return request.post('api/web/v1/qualityMeasure/private/problemLevel/addProblemLevel', data)
  },
  // 修改问题等级
  modifyProblemLevel(data) {
    return request.put('api/web/v1/qualityMeasure/private/problemLevel/updateOne', data)
  },
  // 删除问题等级
  delProblemLevel(data) {
    return request.del('api/web/v1/qualityMeasure/private/problemLevel/deleteOne', data)
  },

  // 获取问题分类-分页
  getProblemClassListPage(data) {
    return request.get('api/web/v1/qualityMeasure/private/problemClass/getProblemClassListPage', data)
  },
    // 获取问题分类-不分页
    getProblemClassList(data) {
      return request.get('api/web/v1/qualityMeasure/private/problemClass/getProblemClassList', data)
    },
  // 新增问题分类
  addProblemClass(data) {
    return request.post('api/web/v1/qualityMeasure/private/problemClass/addProblemClass', data)
  },
  // 修改问题分类
  modifyProblemClass(data) {
    return request.put('api/web/v1/qualityMeasure/private/problemClass/updateOne', data)
  },
  // 删除问题分类
  delProblemClass(data) {
    return request.del('api/web/v1/qualityMeasure/private/problemClass/deleteOne', data)
  },
  // 获取难易等级-分页
  getDifficultyLevelListPage(data) {
    return request.get('api/web/v1/qualityMeasure/private/difficultyLevel/getDifficultyLevelListPage', data)
  },
    // 获取难易等级-不分页
    getDifficultyLevelList(data) {
      return request.get('api/web/v1/qualityMeasure/private/difficultyLevel/getDifficultyLevelList', data)
    },
  // 新增难易等级
  addDifficultyLevel(data) {
    return request.post('api/web/v1/qualityMeasure/private/difficultyLevel/addDifficultyLevel', data)
  },
  // 修改难易等级
  modifyDifficultyLevel(data) {
    return request.put('api/web/v1/qualityMeasure/private/difficultyLevel/updateOne', data)
  },
  // 删除难易等级
  delDifficultyLevel(data) {
    return request.del('api/web/v1/qualityMeasure/private/difficultyLevel/deleteOne', data)
  },

// 获取质量措施跟踪单列表
getQualityMeasureTrackingListPage(data) {
  return request.get('api/web/v1/qualityMeasure/private/trackingSheet/getTrackingSheetListPage', data)
},
// 获取质量措施跟踪单号
getQualityMeasureTrackingCode(data) {
  return request.get('api/web/v1/qualityMeasure/private/trackingSheet/getTrackingSheetCode', data)
},
// 新增质量措施跟踪单
addQualityMeasureTracking(data) {
  return request.post('api/web/v1/qualityMeasure/private/problemClass/addTrackingSheet', data)
},
// 获取质量措施跟踪单详情
getTrackingSheetDetail(data) {
  return request.get('api/web/v1/qualityMeasure/private/problemClass/getTrackingSheetDetail', data)
},
// 新建跟进记录
addFollowLog(data) {
  return request.post('api/web/v1/qualityMeasure/private/problemClass/addFollowLog', data)
},
// 处理质量措施跟踪单
handleTrackingSheet(data) {
  return request.post('api/web/v1/qualityMeasure/private/problemClass/handleSheet', data)
},
// 关闭质量措施跟踪单
closeSheet(data) {
  return request.post('api/web/v1/qualityMeasure/private/problemClass/closeSheet', data)
},
// 获取改善状态--分页
getImproveStateListPage(data){
  return request.get('api/web/v1/qualityMeasure/private/improvedStatus/getImprovedStatusListPage', data)
},
// 获取改善状态--不分页
getImproveStateList(data){
  return request.get('api/web/v1/qualityMeasure/private/improvedStatus/getImprovedStatusList', data)
},
 // 新增改善状态
addImproveState(data) {
  return request.post('api/web/v1/qualityMeasure/private/improvedStatus/addImprovedStatus', data)
},
// 修改改善状态
modifyImproveState(data) {
  return request.put('api/web/v1/qualityMeasure/private/improvedStatus/updateOne', data)
},
// 删除改善状态
delImproveState(data) {
  return request.del('api/web/v1/qualityMeasure/private/improvedStatus/deleteOne', data)
},
}
