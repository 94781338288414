import request from '@/utils/requestForAsync'
const baseUrl = 'api/web/v1/inspection/private/inspectionScheme/'

export default {
  // 获取质检方案不分页
  getInspectionPlanList(data) {
    return request.get(baseUrl + 'getInspectionSchemeList', data)
  },
  // 获取质检方案下拉列表
  getInspectionPullDownList(data) {
    return request.get(baseUrl + 'getInspectionPullDownList', data)
  },
  // 获取质检方案-分页
  getInspectionPlanListPage(data) {
    return request.get(baseUrl + 'getInspectionSchemeListPage', data)
  },
  // 新增质检方案
  addInspectionPlan(data) {
    return request.post(baseUrl + 'addInspectionScheme', data)
  },
  // 新增质检方案
  copyInspectionPlan(data) {
    return request.post(baseUrl + 'addInspectionScheme', data)
  },
  // 修改质检方案
  modifyInspectionPlan(data) {
    return request.put(baseUrl + 'updateOne', data)
  },
  // 删除质检方案
  delInspectionPlan(data) {
    return request.del(baseUrl + 'deleteOne', data)
  },
  // 启用/停用
  switchStatus(status, path, id) {
    return request.get(baseUrl + `${status}?id=${id}`)
  },
  getInspectionPlanDetailById(params) {
    return request.get(baseUrl + 'getInspectionSchemeDetail', params)
  },
  synchronousInspectionPlan(params) {
    return request.get(baseUrl + 'syncToInspectionSheet', params)
  },
  // 导出质检方案模板
  exportInspectionScheme(data) {
    return request.exportPost(baseUrl + 'export', data)
  },
  // 质检方案同步至工艺路线和工单
  syncToProcessFlow(data) {
      return request.get(baseUrl + 'syncToProcessFlow', data)
    }
}
